<template>
  <div>
    <div id="headerContainer" class="header" :style="headerStyle">
      <div class="logo-cont">
        <RouterLink to="/homepage"><img src="@/assets/imgs/logo-white.webp" class="" /></RouterLink>
      </div>
      <div class="menu">
        <div
          v-for="(menuItem, index) in menuItems"
          :key="index"
          :class="{ active: activeIndex === index, hasChild: menuItem.children && !menuItem.to }"
          class="menu-item"
        >
          <template v-if="menuItem.to">
            <router-link :to="menuItem.to" class="menu-link" @click.native="setActiveIndex(index)">
              {{ menuItem.text }}
              <div v-show="activeIndex === index" class="underline"></div>
            </router-link>
          </template>
          <template v-else>
            <span class="menu-link menu-haschild">
              {{ menuItem.text }}
              <div v-show="activeIndex === index" class="underline"></div>
            </span>
            <template v-if="menuItem.children">
              <div class="submenu">
                <div class="submenu-cont">
                  <router-link
                    v-for="sub in menuItem.children"
                    :key="`${sub.to}_${sub.text}`"
                    :to="sub.to"
                    class="submenu-item"
                  >
                    <template v-if="sub.className">
                      <span :class="sub.className"></span>
                    </template>
                    {{ sub.text }}
                  </router-link>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <!-- 切换语言 -->
      <div class="lang" :style="langStyle">
        <img src="@/assets/header/language.svg" alt="" :style="langStyle()" />
        <span>En</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: process.env.VUE_APP_REMOTE_IMG_URL,
      searchText: '',
      productNo: '',
      defaultList: [],
      productsList: [],
      isActive: false,
      categoryList: [],
      activeIndex: -1, // 默认选中第一个
      defaultActiveIndex: 1, // 默认的“PRODUCT”选项索引
      menuItems: [
        { text: this.$t('header.welcome'), to: '/homepage' },
        {
          text: this.$t('header.product'), //  to: '/ProductPage'
          children: [
            // { text: this.$t('header.product'), to: '/ProductPage' },
            { text: this.$t('header.downloads'), to: '/ProductPage', className: 'download' }
          ]
        },
        { text: this.$t('header.aboutUs'), to: '/aboutus' },
        { text: this.$t('header.news'), to: '/news' },
        { text: this.$t('header.contactUs'), to: '/contactus' }
      ]
    }
  },
  mounted() {
    this.updateActiveIndex()
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index
    },
    updateActiveIndex() {
      const currentPath = this.$route.path
      if (
        currentPath === '/product' ||
        currentPath === '/Magnifier' ||
        currentPath === '/ProductPage'
      ) {
        this.activeIndex = 1
      } else if (currentPath === '/news' || currentPath === '/NewsDetails') {
        this.activeIndex = 3
      } else {
        const index = this.menuItems.findIndex(item => item.to === currentPath)
        this.activeIndex = index !== -1 ? index : -1
      }
    },
    langStyle() {
      const isHome = this.$route.path === '/welcome'
      return {
        filter: isHome ? 'invert(100%) brightness(100%)' : 'none'
      }
    }
  },
  watch: {
    '$route.path'(to) {
      this.updateActiveIndex()
    }
  },
  computed: {
    headerStyle() {
      const isWelcome = this.$route.path === '/welcome'
      const isHome = this.$route.path === '/homepage'
      const isProduct = this.$route.path === '/product'
      const isMagnifier = this.$route.path === '/Magnifier'
      const isAbout = this.$route.path === '/aboutus'
      const isCurrency = this.$route.path === '/currency'
      const isMisInstruments = this.$route.path === '/misInstruments'
      return {
        backgroundColor: isWelcome ? 'black' : 'white',
        color: isWelcome ? 'white' : 'black',
        boxShadow:
          isWelcome ||
          isHome ||
          isProduct ||
          isMagnifier ||
          isAbout ||
          isCurrency ||
          isMisInstruments
            ? 'none'
            : '0px 8px 20px 3px rgba(11,24,61,0.1)'
        // : '9px 2px 14px 3px rgba(204, 204, 204, 0.7)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes productHideSubMenu {
  0% {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}
@keyframes productShowSubMenu {
  0% {
    -webkit-transform: translate3d(0, 300px, 0) translateZ(0) scale(1);
    transform: translate3d(0, 300px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes menu-data {
  0% {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes menu-data-hide {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    -webkit-transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }
}

.header {
  padding: 0 30px;
  position: relative;
  z-index: 20;

  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  // animation: menu-data 0.5s;

  &.hide {
    animation: menu-data-hide 0.5s;
    transform: translate3d(0, -110px, 0) translateZ(0) scale(1);
  }
  .logo-cont {
    width: 150px;
    height: 46px;

    img {
      width: 100%;
    }
  }
  .menu {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .menu-item {
    margin-left: 50px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-style: normal;
    position: relative;
    z-index: 90;
  }

  .menu-link {
    padding-top: 30px;
    width: 100%;
    color: inherit;
    text-decoration: none;
    position: relative;
    display: inline-block;
    z-index: 90;
  }

  .hasChild {
    &:hover {
      .submenu {
        animation: productHideSubMenu 0.3s;
        display: block;
      }
    }
  }

  .submenu {
    position: absolute;
    top: 100%;
    left: -20px;
    width: 180px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    display: none;
    animation: productHideSubMenu 0.3s;
    z-index: 80;

    .submenu-cont {
      padding: 20px 15px 20px 20px;
      display: flex;
      flex-direction: column;

      .download {
        display: inline-block;
        width: 23px;
        height: 20px;
        margin-right: 10px;
        transition: all 0.3s;

        background: url('@/assets/images/header/download-1.png') no-repeat;
        background-size: 23px 20px;
        position: relative;
        top: 2px;
      }

      a {
        color: #000;
        text-align: left;
        font-weight: 500;
        line-height: 30px;

        &:hover {
          color: #005bac;
          font-weight: 600;

          .download {
            background-image: url('@/assets/images/header/download-2.png');
          }
        }
      }
    }
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 50%; // 将下划线居中
    transform: translateX(-50%); // 使下划线居中
    height: 3px;
    width: 40px; // 下划线宽度
    background: radial-gradient(circle at 0% 0%, #76cfff 0%, #66a6ff 100%), #ffffff;
    border-radius: 2px;
    transition: width 0.3s ease;
    margin-bottom: -8px;
  }

  .lang {
    margin-top: 20px;
    display: flex;
    img {
      width: 29px;
      height: 29px;
    }
    span {
      margin-left: 6px;
      font-weight: 400;
      font-size: 20px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }

  .menu {
    display: none;
  }
}

@media (min-width: 640px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
  .header {
    padding: 0 10px;
    .menu {
      display: flex;

      .menu-item {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
@media (min-width: 1280px) {
  .header {
    padding: 0 75px;
    .menu {
      .menu-item {
        margin-left: 50px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
@media (min-width: 1536px) {
}
</style>
