import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/pc/views/home/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    name: 'welcome',
    component: HomeView,
    meta: { hidden: true, title: '首页' }
  },
  {
    path: '/',
    name: 'pages',
    component: () => import('@/pc/components/MainPageTpl.vue'),
    redirect: '/welcome',
    meta: { hidden: true, title: '首页' },
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        component: () => import('@/pc/views/product/product.vue'),
        meta: { hidden: true, title: '首页' }
      },
      {
        path: 'product',
        name: 'product',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/product/product.vue'),
        meta: { hidden: true, title: '产品' }
      },
      {
        path: 'Magnifier',
        name: 'Magnifier',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/product/Magnifier.vue'),
        meta: { hidden: true, title: '放大镜' }
      },
      {
        path: 'ProductPage',
        name: 'ProductPage',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/product/ProductPage.vue'),
        meta: { hidden: true, title: '产品' }
      },
      {
        path: 'aboutus',
        name: 'aboutus',
        component: () => import(/* webpackChunkName: "aboutus" */ '@/pc/views/about/AboutUs.vue'),
        meta: { hidden: true, title: '关于我们' }
      },

      {
        path: 'news',
        name: 'newsList',
        component: () => import(/* webpackChunkName: "services" */ '@/pc/views/news/newsList.vue'),
        meta: { hidden: true, title: '新闻' }
      },
      {
        path: 'newslist1',
        name: 'newsList1',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/list/newsList-1.vue'),
        meta: { hidden: true, title: '新闻' }
      },
      {
        path: 'newslist2',
        name: 'newsList2',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/list/newsList-2.vue'),
        meta: { hidden: true, title: '新闻' }
      },
      // {
      //   path: 'news',
      //   name: 'news',
      //   component: () => import(/* webpackChunkName: "services" */ '@/pc/views/news/news.vue'),
      //   meta: { hidden: true, title: '新闻' }
      // },
      {
        path: 'NewsDetails',
        name: 'NewsDetails',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/NewsDetails.vue'),
        meta: { hidden: true, title: '新闻详情' }
      },
      {
        path: 'news1',
        name: 'news1',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/details/news1.vue'),
        meta: { hidden: true, title: '新闻详情' }
      },
      {
        path: 'news2',
        name: 'news2',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/details/news2.vue'),
        meta: { hidden: true, title: '新闻详情' }
      },
      {
        path: 'news3',
        name: 'news3',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/pc/views/news/details/news3.vue'),
        meta: { hidden: true, title: '新闻详情' }
      },
      {
        path: 'contactus',
        name: 'contactus',
        component: () =>
          import(/* webpackChunkName: "contactus" */ '@/pc/views/contactus/ContactPage.vue'),
        meta: { hidden: true, title: '联系我们' }
      },
      {
        path: 'productList',
        name: 'productList',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/pc/views/product/ProductList.vue'),
        meta: { hidden: true, title: '产品列表' }
      },
      {
        path: 'productListAll',
        name: 'productListAll',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/pc/views/product/ProductListAll.vue'),
        meta: { hidden: true, title: '产品列表' }
      },
      {
        path: 'productInfo',
        name: 'productInfo',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/pc/views/product/ProductInfo.vue'),
        meta: { hidden: true, title: '产品详情' }
      },
      {
        path: 'headlight',
        name: 'headlight',
        component: () =>
          import(/* webpackChunkName: "glasses" */ '@/pc/views/product/Headlight.vue'),
        meta: { hidden: true, title: '眼镜' }
      },
      {
        path: 'privacy',
        name: 'privacyPage',
        component: () =>
          import(/* webpackChunkName: "privacy" */ '@/pc/views/privacy/privacyPage.vue'),
        meta: { hidden: true, title: '隐私政策' }
      },
      {
        path: 'currency',
        name: 'currency',
        component: () =>
          import(/* webpackChunkName: "currency" */ '@/pc/views/subpage/currency.vue'),
        meta: { hidden: true, title: '通用二级页面' }
      },
      {
        path: 'misInstruments',
        name: 'misInstruments',
        component: () =>
          import(/* webpackChunkName: "misInstruments" */ '@/pc/views/subpage/misInstruments.vue'),
        meta: { hidden: true, title: '微创二级页面' }
      },
      {
        path: 'recommendedProducts',
        name: 'recommendedProducts',
        component: () => {
          return import(
            /* webpackChunkName: "recommendedProducts" */ '@/pc/views/product/Recommend.vue'
          )
        },
        meta: { hidden: true, title: '产品推荐' }
      },
      {
        path: 'download',
        name: 'download',
        component: () => import('@/pc/components/DownloadTpl.vue'),
        meta: { hidden: true, title: '通用二级页面' },
        children: [
          {
            path: 'MicroClamps',
            name: 'MicroClamps',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroClamps.vue'),
            meta: { hidden: true, title: 'Micro Clamps下载' }
          },
          {
            path: 'HemostaticForceps',
            name: 'HemostaticForceps',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/HemostaticForceps.vue'
              ),
            meta: { hidden: true, title: 'Hemostatic Forceps下载' }
          },
          {
            path: 'MicroTissueForceps',
            name: 'MicroTissueForceps',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroTissueForceps.vue'
              ),
            meta: { hidden: true, title: 'Micro Tissue Forceps下载' }
          },
          {
            path: 'MicroScissors',
            name: 'MicroScissors',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroScissors.vue'),
            meta: { hidden: true, title: 'Micro Scissors下载' }
          },
          {
            path: 'NeedleHolders',
            name: 'NeedleHolders',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/NeedleHolders.vue'),
            meta: { hidden: true, title: 'Needle Holders下载' }
          },
          {
            path: 'Spreaders',
            name: 'Spreaders',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/Spreaders.vue'),
            meta: { hidden: true, title: 'Spreaders下载' }
          },
          {
            path: 'Retractors',
            name: 'Retractors',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/Retractors.vue'),
            meta: { hidden: true, title: 'Retractors下载' }
          },
          {
            path: 'Puncher',
            name: 'Puncher',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/Puncher.vue'),
            meta: { hidden: true, title: 'Puncher下载' }
          },
          {
            path: 'MedicalScissors',
            name: 'MedicalScissors',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MedicalScissors.vue'
              ),
            meta: { hidden: true, title: 'Medical Scissors下载' }
          },
          {
            path: 'MicroNeedleHolders',
            name: 'MicroNeedleHolders',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroNeedleHolders.vue'
              ),
            meta: { hidden: true, title: 'Micro Needle Holders下载' }
          },
          {
            path: 'RetractorsFixtures',
            name: 'RetractorsFixtures',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/RetractorsFixtures.vue'
              ),
            meta: { hidden: true, title: 'Retractors Fixtures下载' }
          },
          {
            path: 'Dissector',
            name: 'Dissector',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/Dissector.vue'),
            meta: { hidden: true, title: 'Dissector下载' }
          },
          {
            path: 'LigatureForceps',
            name: 'LigatureForceps',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/LigatureForceps.vue'
              ),
            meta: { hidden: true, title: 'Ligature Forceps下载' }
          },
          {
            path: 'SuctionTubes',
            name: 'SuctionTubes',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/SuctionTubes.vue'),
            meta: { hidden: true, title: 'Suction Tubes下载' }
          },
          {
            path: 'BileDuctDilators',
            name: 'BileDuctDilators',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/BileDuctDilators.vue'
              ),
            meta: { hidden: true, title: 'Bile Duct Dilators下载' }
          },
          {
            path: 'MicroClampsTwo',
            name: 'MicroClampsTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroClampsTwo.vue'
              ),
            meta: { hidden: true, title: 'Micro Clamps下载' }
          },
          {
            path: 'HemostaticForcepsTwo',
            name: 'HemostaticForcepsTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/HemostaticForcepsTwo.vue'
              ),
            meta: { hidden: true, title: 'Hemostatic Forceps下载' }
          },
          {
            path: 'MicroTissueForcepsTwo',
            name: 'MicroTissueForcepsTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroTissueForcepsTwo.vue'
              ),
            meta: { hidden: true, title: 'Micro Tissue Forceps下载' }
          },
          {
            path: 'MicroScissorsTwo',
            name: 'MicroScissorsTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroScissorsTwo.vue'
              ),
            meta: { hidden: true, title: 'Micro Scissors下载' }
          },
          {
            path: 'NeedleHoldersTwo',
            name: 'NeedleHoldersTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/NeedleHoldersTwo.vue'
              ),
            meta: { hidden: true, title: 'Needle Holders下载' }
          },
          {
            path: 'SpreadersTwo',
            name: 'SpreadersTwo',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/SpreadersTwo.vue'),
            meta: { hidden: true, title: 'Spreaders下载' }
          },
          {
            path: 'MedicalScissorsTwo',
            name: 'MedicalScissorsTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MedicalScissorsTwo.vue'
              ),
            meta: { hidden: true, title: 'Medical Scissors下载' }
          },
          {
            path: 'MicroNeedleHoldersTwo',
            name: 'MicroNeedleHoldersTwo',
            component: () =>
              import(
                /* webpackChunkName: "download" */ '@/pc/views/pdfdownload/MicroNeedleHoldersTwo.vue'
              ),
            meta: { hidden: true, title: 'Micro Needle Holders下载' }
          },
          {
            path: 'Specialty',
            name: 'Specialty',
            component: () =>
              import(/* webpackChunkName: "download" */ '@/pc/views/pdfdownload/Specialty.vue'),
            meta: { hidden: true, title: 'Specialty下载' }
          }
        ]
      }
      // {
      //   path: 'search',
      //   name: 'searchResult',
      //   component: () =>
      //     import(/* webpackChunkName: "privacy" */ '@/pc/views/search/searchResult.vue'),
      //   meta: {
      //     hidden: true,
      //     title: '搜索结果'
      //   }
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  }
})

export default router
