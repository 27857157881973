<template>
  <div class="home-page">
    <div class="header-contain wow animate__fadeInDown" data-wow-duration="2s">
      <!-- <transition name="fade"> -->
      <header-cont class="header-cont" />
      <!-- </transition> -->
    </div>
    <div class="big-img">
      <div class="left-img">
        <img
          src="@/assets/images/welcome/welcome-bg-left.webp"
          alt=""
          class="wow animate__fadeInUp"
          data-wow-duration="2s"
        />
      </div>
      <div class="right-video">
        <video
          src="@/assets/images/welcome/welcome-video.mp4"
          autoplay
          loop
          muted
          class="video-cont wow animate__fadeInUp"
          data-wow-duration="2s"
        ></video>
      </div>
      <div class="home-title">
        <!-- <h4>HICHEST QUALITY SURGICAL INSTRUMENTS</h4>
        <p>Innovation Leads Development Technology Wins the Future</p>
        <h5>OpInstruments GmbH</h5> -->
        <img
          src="@/assets/images/welcome/welcome-text.webp"
          alt=""
          class="welcome-text-img wow animate__fadeInUp"
          data-wow-duration="2s"
        />
        <p class="wow animate__fadeInUp" data-wow-duration="2s">
          Innovation Leads Development Technology Wins the Future
        </p>
        <h5 class="wow animate__fadeInUp" data-wow-duration="2s">OpInstruments GmbH</h5>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCont from '@/pc/components/Header.vue'

export default {
  components: {
    HeaderCont
  },
  data() {
    return {
      isShowNavigation: false // 初始为 false，隐藏导航栏
    }
  },
  mounted() {
    // 设置定时器，3秒后显示导航栏
    setTimeout(() => {
      this.isShowNavigation = true
    }, 1000)

    this.$nextTick(() => {
      this.$wow.init()
    })
  },
  beforeRouteEnter(to, from, next) {
    // 如果从其他页面返回到首页，保持导航栏显示
    next(vm => {
      if (from.name) {
        vm.isShowNavigation = true // 保持导航栏显示
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  min-height: 100vh;
  background: #000;
  position: relative;

  .header-contain {
    width: 100%;
    height: 110px;
    background: #000;
  }
  .img {
    width: 100%;
    height: 100vh;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .home-title {
    position: absolute;
    top: 26%;
    left: 23%;

    .welcome-text-img {
      width: 425px;
      height: 236px;
    }

    // h4 {
    //   // eslint-disable-next-line prettier/prettier
    //   font-size: 44px;
    //   font-weight: bold;
    //   background: radial-gradient(ellipse at center, #89f7fe 0%, #66a6ff 100%);
    //   -webkit-background-clip: text;
    //   background-clip: text;
    //   -webkit-text-fill-color: transparent;
    //   width: 400px;
    //   word-wrap: break-word;
    // }
    p {
      font-size: 20px;
      color: #fff;
      margin: 10px 0 0;
      word-wrap: break-word;
      width: 339px;
    }
    h5 {
      margin-top: 14px;
      font-size: 28px;
      font-weight: bold;
      // background: linear-gradient(30deg, #89f7fe 0%, #66a6ff 70%);
      // -webkit-background-clip: text;
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
      color: #8dedff;
      width: 318px;
      word-wrap: break-word;
    }
  }

  .left-img {
    position: absolute;
    left: 0;
    bottom: 0;

    img {
      width: 788px;
      height: 950px;
    }
  }
  .right-video {
    width: 600px;
    height: 850px;
    position: absolute;
    right: 90px;
    bottom: 0;
    overflow: hidden;

    .video-cont {
      // margin-bottom: 100px;
      width: 600px;
      // height: 1333px;
    }
  }
}

@media (min-width: 640px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1280px) {
}
@media (min-width: 1536px) {
}
</style>
